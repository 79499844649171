import {
  AbuseDetailsKeys,
  AbuseDetailsLevel1VariantKeys,
  AbuseDetailsLevel2VariantKeys,
  AbuseDetailsLevel3VariantKeys,
  AbuseDetailsLevel4VariantKeys,
  AbuseDetailsLevel5VariantKeys,
  EIncludeExcludeKeys,
  LegalDetailsKeys,
  ResolutionPublicityVariantsKey,
  StaticPropertyKey,
  VictimInfoAgeCategoryVariantKeys,
  VictimInfoGenderVariantKeys,
  VictimInfoKeys,
} from '@bvi/api-interfaces/entity/case-property';
import {
  AdditionalFilterParameter,
  CaseStatisticsLevel,
} from '@bvi/api-interfaces/request/case';

export default {
  translation: {
    form: {
      [StaticPropertyKey.COUNTRY]: {
        label: 'Country',
        placeholder: 'Select',
      },
      [StaticPropertyKey.REGION]: {
        label: 'Region',
        placeholder: 'Select',
      },
      [StaticPropertyKey.STATE]: {
        label: 'State/Province',
        placeholder: 'Select',
      },
      [VictimInfoKeys.AGE_CATEGORY]: {
        label: 'Age Category',
        placeholder: 'Select',
      },
      [AdditionalFilterParameter.REFINE_AGE_CATEGORY]: {
        label: 'Refine Age Category',
        placeholder: 'Select',
      },
      [VictimInfoKeys.AGE_AT_START_OF_ABUSE]: {
        label: 'Age at start of abuse',
        placeholder: 'From',
      },
      [VictimInfoKeys.AGE_AT_END_OF_ABUSE]: {
        label: 'Age at end of abuse',
        placeholder: 'To',
      },
      [VictimInfoKeys.STARTED_ABUSE_AT]: {
        label: 'Started Abuse At',
        placeholder: 'From',
      },
      [VictimInfoKeys.END_OF_ABUSE_AT]: {
        label: 'Ended Abuse At',
        placeholder: 'To',
      },
      resolutionDateRange: {
        title: 'Resolution Date',
      },
      [AdditionalFilterParameter.RESOLUTION_DATE_LOW]: {
        label: 'Resolution after',
        placeholder: 'After',
      },
      [AdditionalFilterParameter.RESOLUTION_DATE_HIGH]: {
        label: 'Resolution before',
        placeholder: 'Before',
      },
      periodOfAbuse: {
        label: 'Dates / Period of Abuse',
      },
      numberOfIncidents: {
        label: 'Number of Incidents',
      },
      [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]: {
        label: 'Number of incedents from',
        placeholder: 'From',
      },
      [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]: {
        label: 'Number of incedents to',
        placeholder: 'To',
      },
      [AdditionalFilterParameter.LEVEL]: {
        label: 'Severity of Abuse',
        level1: 'Level 1',
        level2: 'Level 2',
        level3: 'Level 3',
        level4: 'Level 4',
        level5: 'Level 5',
        other: 'Other',
      },
      [AbuseDetailsKeys.LEVEL_1]: {
        label: 'Level 1',
        placeholder: 'Select',
      },
      [AbuseDetailsKeys.LEVEL_2]: {
        label: 'Level 2',
        placeholder: 'Select',
      },
      [AbuseDetailsKeys.LEVEL_3]: {
        label: 'Level 3',
        placeholder: 'Select',
      },
      [AbuseDetailsKeys.LEVEL_4]: {
        label: 'Level 4',
        placeholder: 'Select',
      },
      [AbuseDetailsKeys.LEVEL_5]: {
        label: 'Level 5',
        placeholder: 'Select',
      },
      [VictimInfoKeys.GENDER]: {
        label: 'Gender of Victim',
        placeholder: 'All',
      },
      ageRangeOfAbuse: {
        label: 'Age / Age Range When Abused',
      },
      [StaticPropertyKey.ORGANIZATION]: {
        label: 'Organization / Church / Employer of Perpetrator',
        placeholder: 'Select',
      },
      [StaticPropertyKey.DIOCESE]: {
        label: 'Subsidiary',
        placeholder: 'Select',
      },
      [StaticPropertyKey.ORGANIZATION_TYPE]: {
        label: 'Organization Type',
        placeholder: 'Select',
      },
      [StaticPropertyKey.JOB_TITLE]: {
        label: 'Job/Position of Perpetrator',
        placeholder: 'Select',
      },
      [StaticPropertyKey.ORDER]: {
        label: 'Perpetrator Religious Order',
        placeholder: 'Select',
      },
      [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]: {
        label: 'Number of Perpetrators from',
        placeholder: 'From',
      },
      [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]: {
        label: 'Number of Perpetrators to',
        placeholder: 'To',
      },
      numberOfPerpetrators: {
        label: 'Number of Perpetrators',
        from: {
          label: '',
          placeholder: 'From',
        },
        to: {
          label: '',
          placeholder: 'To',
        },
      },
      [StaticPropertyKey.LIABILITY_DEFENSES]: {
        label: 'Defenses to Liability',
        placeholder: 'Select',
      },
      [StaticPropertyKey.EXCEPTION]: {
        label: 'Exceptions/Rebuttals to Defenses',
        placeholder: 'Select',
      },
      [LegalDetailsKeys.PREPARATOR]: {
        label: 'Perpetrator Denial',
      },
      [LegalDetailsKeys.LAWSUIT_FILED]: {
        label: 'Lawsuit Filed',
      },
      [LegalDetailsKeys.HAS_ATTORNEY]: {
        label: 'Attorney Retained',
      },
      [StaticPropertyKey.RESOLUTION_TYPE]: {
        label: 'Settlements / Verdicts / Other Resolutions',
        placeholder: 'All',
      },
      additionalDateFilters: {
        label: 'Additional Date Filters',
        placeholder: 'Select',
      },
      includeExcludeInformation: {
        title: 'Include / Exclude Claim Values',
      },
      [EIncludeExcludeKeys.CLASS_ACTION_NAME]: {
        label: 'Class Action',
      },
      [EIncludeExcludeKeys.BANKRUPTCY_NAME]: {
        label: 'Bankruptcy Claims',
      },
      [EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME]: {
        label: 'Compensation Funds / Redress Schemes',
      },
      victimInformation: {
        title: 'Victim Information',
      },
      claimNumber: {
        label: 'Claim Number',
        placeholder: 'Enter claim number',
      },
      abuseDetails: {
        title: 'Abuse Details',
      },
      responsibleParty: {
        title: 'Perpetrator and / or Responsible Party',
      },
      legalDetails: {
        title: 'Legal Details',
      },
      resolutionDetails: {
        title: 'Resolution Details',
      },
      resetFilterButton: 'Clear All Filters',
      settlementRange: {
        label: 'Settlement Range',
      },
      [AdditionalFilterParameter.AMOUNT_LOW]: {
        label: 'Settlement Amount from',
        placeholder: 'From',
      },
      [AdditionalFilterParameter.AMOUNT_HIGH]: {
        label: 'Settlement Amount to',
        placeholder: 'To',
      },
      abuseTypeItems: {
        [AbuseDetailsLevel1VariantKeys.SUGGESTIVE_SEXUAL_TALK]:
          'Suggestive Sexual Talk',
        [AbuseDetailsLevel1VariantKeys.KISSING_ANG_HUGGING]:
          'Kissing/Kissing and Hugging',
        [AbuseDetailsLevel1VariantKeys.TOUCHING_NON_PRIVATE_PARTS]:
          'Touched Non-Private Parts',
        [AbuseDetailsLevel1VariantKeys.PROVIDING_VIEWING_PORNOGRAPHY]:
          'Providing/Viewing Pornography',
        [AbuseDetailsLevel1VariantKeys.EXPOSURE_OF_PERPETRATOR_TO_GENITALS]:
          'Exposure of Perpetrator’s Genitals',
        [AbuseDetailsLevel1VariantKeys.BUTTOCKS_FONDING]: 'Buttocks Fondling',
        [AbuseDetailsLevel2VariantKeys.WITNESS_PERPETRATOR_MASTURBATE]:
          'Witness Perpetrator Masturbate',
        [AbuseDetailsLevel2VariantKeys.PROVIDED_ALCOHOL_DRUGS]:
          'Provided Alcohol/Drugs ',
        [AbuseDetailsLevel2VariantKeys.FRENCH_KISSING]: '“French Kissing”',
        [AbuseDetailsLevel2VariantKeys.BREAST_FONDLING]: 'Breast Fondling ',
        [AbuseDetailsLevel2VariantKeys.PRESSING_GENITALS_AGAINST_CLAIMANT]:
          'Pressing Genitals Against Claimant',
        [AbuseDetailsLevel2VariantKeys.CLAIMANT_IS_UNDRESSED_NAKED]:
          'Claimant is Undressed/Naked',
        [AbuseDetailsLevel2VariantKeys.OVER_THE_CLOTHES_GENITAL_FONDLING]:
          'Over the Clothes Genital Fondling',
        [AbuseDetailsLevel3VariantKeys.MUTUAL_SELF_MASTURBATION]:
          'Mutual Self-Masturbation',
        [AbuseDetailsLevel3VariantKeys.MUTUAL_MASTURBATION_OF_EACH_OTHER]:
          'Mutual Masturbation of Each Other ',
        [AbuseDetailsLevel3VariantKeys.SEX_TOYS_PARAPHERNALIA]:
          'Sex Toys/Paraphernalia ',
        [AbuseDetailsLevel3VariantKeys.COERCED_MASTURBATION_OF_PERPETRATOR]:
          'Coerced Masturbation of Perpetrator',
        [AbuseDetailsLevel3VariantKeys.SKIN_TO_SKIN_GENITAL_FONDLING]:
          'Skin-to-Skin Genital Fondling ',
        [AbuseDetailsLevel4VariantKeys.ORAL_SEX_UPON_CLAIMANT]:
          'Oral Sex Upon Claimant',
        [AbuseDetailsLevel4VariantKeys.ATTEMPTED_SODOMY]: 'Attempted Sodomy',
        [AbuseDetailsLevel4VariantKeys.ANAL_DIGITAL_PENETRATION]:
          'Anal Digital Penetration ',
        [AbuseDetailsLevel4VariantKeys.WEAPON_INTIMIDATION]:
          'Weapon Intimidation',
        [AbuseDetailsLevel4VariantKeys.VAGINAL_DIGITAL_PENETRATION]:
          'Vaginal Digital Penetration',
        [AbuseDetailsLevel4VariantKeys.ORAL_ANAL_STIMULATION_OF_CLAIMANT]:
          'Oral/Anal Stimulation of Claimant',
        [AbuseDetailsLevel4VariantKeys.COERCED_ORAL_SEX_UPON_PERPETRATOR]:
          'Coerced Oral Sex Upon Perpetrator',
        [AbuseDetailsLevel5VariantKeys.ANAL_SODOMY]: 'Anal Sodomy',
        [AbuseDetailsLevel5VariantKeys.RAPE]: 'Rape',
        [AbuseDetailsLevel5VariantKeys.VAGINAL_INTERCOURSE]:
          'Vaginal Intercourse',
        [AbuseDetailsLevel5VariantKeys.SEX_TRAFFICKING]: 'Sex Trafficking',
        [AbuseDetailsLevel5VariantKeys.COERCED_ORAL_ANAL_STIMULATION_OF_PERPETRATOR]:
          'Coerced Oral/Anal Stimulation of Perpetrator',
        [AbuseDetailsLevel5VariantKeys.CREATION_OF_CHILD_PORNOGRAPHY_USING_CLAIMANT]:
          'Creation of Child Pornography Using Claimant',
        [AbuseDetailsLevel1VariantKeys.UNSPECIFIED]: 'Unspecified',
      },
      [AdditionalFilterParameter.RESOLUTION_PUBLICITY]: {
        label: 'Publicity of Resolution',
        placeholder: 'Select',
      },
      error: {
        reset: 'Reset',
      },
      advancedSearch: {
        title: 'Advanced Search',
        claimDetailsTitle: 'Claim Details',
      },
    },
    [AdditionalFilterParameter.RESOLUTION_PUBLICITY]: {
      [ResolutionPublicityVariantsKey.CONFIDENTIAL]: 'Confidential',
      [ResolutionPublicityVariantsKey.PUBLIC]: 'Public',
    },
    abuseTypeLevels: {
      [AbuseDetailsKeys.DETAIL_AVAILABLE]: 'detail-available',
      [AbuseDetailsKeys.LEVEL_1]: 'Level 1',
      [AbuseDetailsKeys.LEVEL_2]: 'Level 2',
      [AbuseDetailsKeys.LEVEL_3]: 'Level 3',
      [AbuseDetailsKeys.LEVEL_4]: 'Level 4',
      [AbuseDetailsKeys.LEVEL_5]: 'Level 5',
      [CaseStatisticsLevel.LEVEL_1]: 'Level 1',
      [CaseStatisticsLevel.LEVEL_2]: 'Level 2',
      [CaseStatisticsLevel.LEVEL_3]: 'Level 3',
      [CaseStatisticsLevel.LEVEL_4]: 'Level 4',
      [CaseStatisticsLevel.LEVEL_5]: 'Level 5',
      [AbuseDetailsKeys.LEVEL_1_UNSPECIFIED]: 'level-1-unspecified',
      [AbuseDetailsKeys.LEVEL_2_UNSPECIFIED]: 'level-2-unspecified',
      [AbuseDetailsKeys.LEVEL_3_UNSPECIFIED]: 'level-3-unspecified',
      [AbuseDetailsKeys.LEVEL_4_UNSPECIFIED]: 'level-4-unspecified',
      [AbuseDetailsKeys.LEVEL_5_UNSPECIFIED]: 'level-5-unspecified',
      [AbuseDetailsKeys.OTHER]: 'Other',
      [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]: 'number-of-incidents-low',
      [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]: 'number-of-incidents-high',
      [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]:
        'number-of-perpetrators-low',
      [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]:
        'number-of-perpetrators-high',
    },
    ageCategory: {
      [VictimInfoAgeCategoryVariantKeys.ADULT]: 'Adult',
      [VictimInfoAgeCategoryVariantKeys.MINOR]: 'Minor',
      [VictimInfoAgeCategoryVariantKeys.MINOR_TO_ADULT]: 'Minor to adult',
      [VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT]: 'Teen to adult',
      [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT]: 'Prepubescent',
      [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT]:
        'Prepubescent to adult',
      [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_TEEN]:
        'Prepubescent to teen',
      [VictimInfoAgeCategoryVariantKeys.TEEN]: 'Teen',
    },
    ageCategoryDescription: {
      [VictimInfoAgeCategoryVariantKeys.ADULT]: '18 and Over',
      [VictimInfoAgeCategoryVariantKeys.MINOR]:
        'Under Age 18 but the age may be unknown as yet',
      [VictimInfoAgeCategoryVariantKeys.MINOR_TO_ADULT]:
        'Period of abuse includes unknown specific ages under age 18 but the period of abuse was known to span into ages 18 and over',
      [VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT]:
        'Period of abuse includes teenage years (13 to 17) and spans into ages 18 and over',
      [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT]: 'Under Age 13',
      [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_TEEN]:
        'Age range during period of abuse spanning years when the victim was under age 13 to when victim was into the teenage years but under age 18',
      [VictimInfoAgeCategoryVariantKeys.TEEN]: 'Age 13 to Under Age 18',
      [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT]:
        'Age range during period of abuse spanning years when the victim was under age 13 and spanning into ages 18 and over',
    },
    agreementValues: {
      yes: 'Yes',
      no: 'No',
    },
    filter: {
      title: 'Applied Filters',
      resetFilterButton: 'Clear All Filters',
    },
    caseHading: {
      title: 'Total Current Number of Claimants in the Burnett Index:        ',
    },
    initialFilter: {
      title: 'Initial Search Criteria',
      submitButton: 'Show Results',
    },
    refineFilter: {
      title: 'Refine Your Search',
      form: {
        submitButton: 'Show results',
      },
    },
    results: {
      empty: 'No data for construction',
      table: {
        title: 'Key Metrics Summary',
        titleWithThroughDate: 'Table of Averages resolved through {{to}}',
        titleWithRange:
          'Table of Averages resolved from {{from}} through {{to}}',
        amount: 'Number of Claims {{classAction}} Class Action:',
        bvi: 'BVI',
        overallAverage: 'Mean',
        median: 'Median',
        mode: 'Mode',
        geometricMean: 'Geometric Mean',
        middleHalfMean: 'Middle Half Mean',
        lowestQuartileAverage: 'Lowest Quartile Mean',
        highestQuartileAverage: 'Highest Quartile Mean',
        rowIncludesZeros: 'Results Including $0s',
        rowExcludesZeros: 'Results Excluding $0s',
        notEnoughDataAlert: 'Not enough data to display all statistics',
        tooSmallSample:
          'The number of claims in this sample is very small. Results are shown for your information, but use caution in drawing conclusions when the sample size is less than ten.',
      },
      chart: {
        title:
          'Value Range of Search Results With "Excluding Zero Values" Metrics',
        bestResolutionValue: 'Best Resolution Value',
        bestResolutionValueLegend:
          'Best Resolution Range based on Search Criteria',
        acceptableRangeLegend: 'Range of Means',
        confidentialCasesLegend:
          'Number of confidential values higher and/or lower than displayed public value range',
        middleHalfMeanLabel: 'Middle Half Mean',
        meanLabel: 'Mean',
      },
    },
    resultsChart: {
      title: 'Range of Search Result Claim Values',
    },
    searchReport: {
      title: 'Sexual Abuse Claim Resolution Values',
      dateOfSearch: 'Date of Search',
      country: 'Country',
      numberOfClaims: 'Number of Сlaims:',
      dateRangeOfClaims: 'Date Range of Claims:',
      filter: {
        title: 'Applied Filters:',
      },
      notes: {
        label: 'Please Note:',
        text: 'These results are based on search criteria input by the user. For a more expansive, nuanced expert valuation analysis and interpretation of results, contact Michael Burnett at <mailLink>{{mail}}</mailLink> for sexual abuse claim valuation consulting services and a more comprehensive valuation report. A more expansive valuation analysis serves as a useful complement to these results, providing assistance with more in-depth context in and valuation of complex sexual abuse claims. They have been invaluable in actuarial analyses, underwriting and claims determinations, and in the purchase of insurance coverage in the risk transfer enterprise. Additional expert valuation analysis can be essential to establishing claim reserves, in settlement negotiations and in constantly evolving assessments regarding litigation and decisions about trial or other forms of claim resolution. Ultimately, the goal is to achieve expedited, cost-effective and compassionate resolution of sexual abuse claims.',
      },
    },
    genderValues: {
      [VictimInfoGenderVariantKeys.ALL]: 'All',
      [VictimInfoGenderVariantKeys.FEMALE]: 'Female',
      [VictimInfoGenderVariantKeys.MALE]: 'Male',
      [VictimInfoGenderVariantKeys.UNKNOWN]: 'Unknown',
    },
  },
};
