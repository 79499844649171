/* eslint-disable prettier/prettier */
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  AbuseDetailsKeys,
  AbuseDetailsLevel1VariantKeys,
  AbuseDetailsLevel2VariantKeys,
  AbuseDetailsLevel3VariantKeys,
  AbuseDetailsLevel4VariantKeys,
  AbuseDetailsLevel5VariantKeys,
  Cases,
  ClaimNumber,
  EIncludeExcludeKeys,
  EIncludeExcludeValue,
  StaticPropertyKey,
  VictimInfoGenderVariantKeys,
  VictimInfoKeys,
} from '@bvi/api-interfaces/entity/case-property';
import { AdditionalFilterParameter } from '@bvi/api-interfaces/request/case';
import {
  Autocomplete,
  IOnOptionsLoadParameters,
  Div,
  FormDateRangePicker,
  Select2,
  HelpTooltip
} from '@bvi/common-components'
import { useFormOptions } from '@bvi/form-utils';

import { ISearchFormDataSchema, OnOptionsLoad } from '../../../lib';
import { i18nInstance } from '../../../locales';
import { styles } from '../styles';

type InitialSearchProperties = {
  onOptionsLoad: OnOptionsLoad;
  isFormLoading?: boolean;
};

export const InitialSearch: React.FC<InitialSearchProperties> = (
  properties,
) => {
  const location = useLocation();
  const [searchParameters] = useSearchParams();
  const [claimNumberValue, setClaimNumberValue] = useState<string>(
    searchParameters.get(ClaimNumber.NAME) ?? '',
  );
  const { onOptionsLoad, isFormLoading } = properties;
  const localStorageKey = EIncludeExcludeKeys.CLASS_ACTION_NAME;
  const { control, setValue } = useFormContext<ISearchFormDataSchema>();
  const [localValue, setLocalValue] = useState<EIncludeExcludeValue>(() => {
    const storedValue = localStorage.getItem(localStorageKey);
    return (storedValue ?? EIncludeExcludeValue.YES) as EIncludeExcludeValue;
  });

  useEffect(() => {
    const localValue =
      (localStorage.getItem(localStorageKey) as EIncludeExcludeValue) ||
      EIncludeExcludeValue.YES;
    const storedValue = location.search.includes(
      EIncludeExcludeKeys.CLASS_ACTION_NAME,
    )
      ? EIncludeExcludeValue.YES
      : localValue;
    setLocalValue(storedValue);
    setValue(localStorageKey, storedValue);
    localStorage.setItem(localStorageKey, storedValue);
  }, [location]);

  useEffect(() => {
    setValue(ClaimNumber.NAME, claimNumberValue);
  }, [claimNumberValue, setValue]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as EIncludeExcludeValue;
    localStorage.setItem(localStorageKey, value);
    setLocalValue(value);
    setValue(localStorageKey, value);
  };

  const { t } = useTranslation('', {
    i18n: i18nInstance,
    keyPrefix: 'form',
  });

  const genderOptions = useFormOptions(VictimInfoGenderVariantKeys, 'gender');
  const level1Variants = useFormOptions(
    AbuseDetailsLevel1VariantKeys,
    'abuseTypeItems',
  );
  const level2Variants = useFormOptions(
    AbuseDetailsLevel2VariantKeys,
    'abuseTypeItems',
  );
  const level3Variants = useFormOptions(
    AbuseDetailsLevel3VariantKeys,
    'abuseTypeItems',
  );
  const level4Variants = useFormOptions(
    AbuseDetailsLevel4VariantKeys,
    'abuseTypeItems',
  );
  const level5Variants = useFormOptions(
    AbuseDetailsLevel5VariantKeys,
    'abuseTypeItems',
  );

  const handleLoadJobOptions = async (data: IOnOptionsLoadParameters) =>
    await onOptionsLoad(StaticPropertyKey.JOB_TITLE, data);

  return (
    <Div>
      <Typography variant="h4" sx={styles.subtitle}>
        {t('includeExcludeInformation.title')}
      </Typography>

      <Grid item xs={12} my={4}>
        <FormControl fullWidth>
          <InputLabel>
            {t(`${EIncludeExcludeKeys.CLASS_ACTION_NAME}.label`)}
          </InputLabel>
          <Controller
            name={EIncludeExcludeKeys.CLASS_ACTION_NAME}
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                row
                style={{ marginTop: 8 }}
                value={localValue}
                onChange={(event) => handleRadioChange(event)}
              >
                <FormControlLabel
                  value={EIncludeExcludeValue.NO}
                  control={<Radio />}
                  label="Include"
                />
                <FormControlLabel
                  value={EIncludeExcludeValue.YES}
                  control={<Radio />}
                  label="Exclude"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} my={4}>
        <FormControl fullWidth>
          <InputLabel>
            {t(`${EIncludeExcludeKeys.BANKRUPTCY_NAME}.label`)}
          </InputLabel>
          <Controller
            name={EIncludeExcludeKeys.BANKRUPTCY_NAME}
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                row
                style={{ marginTop: 8 }}
                value={field.value ?? EIncludeExcludeValue.NO}
              >
                <FormControlLabel
                  value={EIncludeExcludeValue.NO}
                  control={<Radio />}
                  label="Include"
                />
                <FormControlLabel
                  value={EIncludeExcludeValue.YES}
                  control={<Radio />}
                  label="Exclude"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} my={4}>
        <FormControl fullWidth>
          <InputLabel>
            {t(`${EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME}.label`)}
          </InputLabel>
          <Controller
            name={EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME}
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                row
                style={{ marginTop: 8 }}
                value={field.value ?? EIncludeExcludeValue.NO}
              >
                <FormControlLabel
                  value={EIncludeExcludeValue.NO}
                  control={<Radio />}
                  label="Include"
                />
                <FormControlLabel
                  value={EIncludeExcludeValue.YES}
                  control={<Radio />}
                  label="Exclude"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>

      {location.pathname.includes(Cases.title) && (
        <>
          <Typography variant="h4" sx={styles.subtitle}>
            {t('claimNumber.label')}
          </Typography>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} my={2}>
              <TextField
                name={ClaimNumber.NAME}
                variant="filled"
                fullWidth
                label={t('claimNumber.label')}
                placeholder={t('claimNumber.placeholder')}
                value={claimNumberValue}
                onChange={(event) => {
                  setClaimNumberValue(event.target.value);
                  setValue(ClaimNumber.NAME, event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Typography variant="h4" sx={styles.subtitle}>
        {t('victimInformation.title')}
      </Typography>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} my={2}>
          <Select2
            name={VictimInfoKeys.GENDER}
            variant="filled"
            multiple
            items={genderOptions}
            fullWidth
            label={t(`${VictimInfoKeys.GENDER}.label`)}
            placeholder={t(`${VictimInfoKeys.GENDER}.placeholder`)}
          />
        </Grid>
      </Grid>

      <Typography variant="h4" sx={styles.subtitle}>
        {t('abuseDetails.title')}
        <span style={{ display: 'inline-block', marginTop: '-7px' }}>
          <HelpTooltip title="Select severity level of abuse or specific types of abuse for a more targeted search" />
        </span>
      </Typography>
      <Grid container columnSpacing={3}>
        <Grid container item xs={12} columnSpacing={3} my={2}>
          <Grid item xs={12} mb={3}>
            <Select2
              name={AbuseDetailsKeys.LEVEL_1}
              variant="filled"
              multiple
              items={level1Variants}
              fullWidth
              placeholder={t(`${AbuseDetailsKeys.LEVEL_1}.placeholder`)}
              label={t(`${AbuseDetailsKeys.LEVEL_1}.label`)}
            />
          </Grid>
          <Grid item xs={12} mb={3}>
            <Select2
              name={AbuseDetailsKeys.LEVEL_2}
              variant="filled"
              multiple
              items={level2Variants}
              fullWidth
              placeholder={t(`${AbuseDetailsKeys.LEVEL_2}.placeholder`)}
              label={t(`${AbuseDetailsKeys.LEVEL_2}.label`)}
            />
          </Grid>
          <Grid item xs={12} mb={3}>
            <Select2
              name={AbuseDetailsKeys.LEVEL_3}
              variant="filled"
              multiple
              items={level3Variants}
              fullWidth
              placeholder={t(`${AbuseDetailsKeys.LEVEL_3}.placeholder`)}
              label={t(`${AbuseDetailsKeys.LEVEL_3}.label`)}
            />
          </Grid>
          <Grid item xs={12} mb={3}>
            <Select2
              name={AbuseDetailsKeys.LEVEL_4}
              variant="filled"
              multiple
              items={level4Variants}
              fullWidth
              placeholder={t(`${AbuseDetailsKeys.LEVEL_4}.placeholder`)}
              label={t(`${AbuseDetailsKeys.LEVEL_4}.label`)}
            />
          </Grid>
          <Grid item xs={12}>
            <Select2
              name={AbuseDetailsKeys.LEVEL_5}
              variant="filled"
              multiple
              items={level5Variants}
              fullWidth
              placeholder={t(`${AbuseDetailsKeys.LEVEL_5}.placeholder`)}
              label={t(`${AbuseDetailsKeys.LEVEL_5}.label`)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={styles.subtitle}>
        {t('responsibleParty.title')}
      </Typography>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} my={2}>
          <Autocomplete
            name={StaticPropertyKey.JOB_TITLE}
            onOptionsLoad={handleLoadJobOptions}
            variant="filled"
            multiple
            isPrefetching={isFormLoading}
            label={t(`${StaticPropertyKey.JOB_TITLE}.label`)}
            placeholder={t(`${StaticPropertyKey.JOB_TITLE}.placeholder`)}
          />
        </Grid>
      </Grid>

      <Typography variant="h4" sx={styles.subtitle}>
        {t('resolutionDateRange.title')}
        <span style={{ display: 'inline-block', marginTop: '-7px' }}>
          <HelpTooltip title="Restrict search to reflect claim values before, after, or between specific dates" />
        </span>
      </Typography>
      <Grid container item xs={12} columnSpacing={3} mb={2}>
        <Grid item xs={12}>
          <FormDateRangePicker
            fields={[
              AdditionalFilterParameter.RESOLUTION_DATE_LOW,
              AdditionalFilterParameter.RESOLUTION_DATE_HIGH,
            ]}
            placeholders={[
              t(`${AdditionalFilterParameter.RESOLUTION_DATE_LOW}.placeholder`),
              t(
                `${AdditionalFilterParameter.RESOLUTION_DATE_HIGH}.placeholder`,
              ),
            ]}
            valueFormat="jsUtcDate"
          />
        </Grid>
      </Grid>
    </Div>
  );
};
