/* eslint-disable prettier/prettier */
/* eslint-disable unicorn/prefer-string-replace-all */
/* eslint-disable unicorn/prevent-abbreviations */
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Stack,
  Switch,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StaticPropertyKey } from '@bvi/api-interfaces/entity/case-property';
import { Div, FormDatePicker } from '@bvi/common-components';

import { defaultValues } from '../../../lib/schema';
import { ICaseFormData } from '../../../lib/types';
import { CasesFormAutocomplete } from '../form-autocomplete';
import { styles } from '../styles';

export const ResolutionDetails = () => {
  const { t } = useTranslation('translation');

  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors: formErrors },
    getValues,
    resetField,
  } = useFormContext<ICaseFormData>();
  const isResolved = watch('isResolved');
  const totalCurrency = watch('resolution.total.currency');
  const perClaimantCurrency = watch('resolution.perClaimant.currency');
  const defenseCostsCurrency = watch('resolution.defenseCosts.currency');
  // const totalAmount = watch('resolution.total.amount');
  // const perClaimantAmount = watch('resolution.perClaimant.amount');
  // const defenseCostsAmount = watch('resolution.defenseCosts.amount');

  useEffect(() => {
    if (!isResolved) {
      resetField('resolution', { defaultValue: defaultValues.resolution });
    }
  }, [resetField, getValues, isResolved]);

  const errors = isResolved
    ? { ...formErrors.resolution, isResolved: true }
    : { ...formErrors.resolution, isResolved: false };

  return (
    <Div>
      <Stack direction="row" alignItems="center" my={2} mb={3}>
        <FormControlLabel
          {...register('isResolved', { setValueAs: Boolean })}
          control={<Switch checked={isResolved} />}
          label={t('cases.form.resolutionDetails.label')}
          labelPlacement="end"
        />
      </Stack>
      {isResolved && (
        <Div>
          <Typography variant="body1" sx={styles.title}>
            {t('cases.form.resolutionDetails.title')}
          </Typography>
          <Grid container spacing={2} my={1}>
            <Grid item xs={3}>
              <FormDatePicker
                field="resolution.resolutionDate"
                fullWidth
                variant="filled"
                valueFormat="jsDate"
                label={t('cases.form.resolutionDetails.resolutionDate.label')}
                placeholder={t(
                  'cases.form.resolutionDetails.resolutionDate.placeholder',
                )}
                error={Boolean(errors?.resolutionDate)}
                helperText={<>{errors.resolutionDate?.message}</>}
                maxDate={dayjs(new Date())}
              />
            </Grid>
            <Grid item xs={3}>
              <Div sx={styles.inputWrapper}>
                <InputLabel variant="filled" sx={{ position: 'absolute' }}>
                  {t(
                    'cases.form.resolutionDetails.totalResolutionAmount.label',
                  )}
                </InputLabel>
                <Grid container columnSpacing={1}>
                  <Grid item xs={4}>
                    <CasesFormAutocomplete
                      name="resolution.total.currency"
                      field={StaticPropertyKey.RESOLUTION_CURRENCY}
                      variant="filled"
                      resetOnChange={() =>
                        resetField('resolution.total.amount', {
                          defaultValue: 0,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      {...register('resolution.total.amount', {
                        setValueAs: (value) =>
                          value
                            ? Number(value.toString().replaceAll(',', '')) || 0
                            : 0,
                      })}
                      value={new Intl.NumberFormat('en-US').format(
                        Number(watch('resolution.total.amount')),
                      )}
                      onChange={(e) => {
                        setValue(
                          'resolution.total.amount',
                          Number(e.target.value.replace(/,/g, '')) || 0,
                          {
                            shouldValidate: true,
                            shouldDirty: true,
                          },
                        );
                      }}
                      variant="filled"
                      fullWidth
                      type="text"
                      error={Boolean(errors?.total?.amount)}
                      helperText={errors?.total?.amount?.message}
                      disabled={!totalCurrency}
                    />
                  </Grid>
                </Grid>
              </Div>
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('resolution.claimantsCount', {
                  setValueAs: (value) => (value ? Number(value) : null),
                })}
                type="number"
                variant="filled"
                fullWidth
                label={t('cases.form.resolutionDetails.claimantsCount.label')}
                helperText={<>{errors.claimantsCount?.message}</>}
                error={Boolean(errors.claimantsCount)}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <Div sx={styles.inputWrapper}>
                <InputLabel variant="filled" sx={{ position: 'absolute' }}>
                  {t('cases.form.resolutionDetails.amountPerClaimant.label')}
                </InputLabel>
                <Grid container columnSpacing={1}>
                  <Grid item xs={4}>
                    <CasesFormAutocomplete
                      name="resolution.perClaimant.currency"
                      field={StaticPropertyKey.RESOLUTION_CURRENCY}
                      variant="filled"
                      resetOnChange={() =>
                        resetField('resolution.perClaimant.amount', {
                          defaultValue: 0,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      {...register('resolution.perClaimant.amount', {
                        setValueAs: (value) =>
                          value
                            ? Number(value.toString().replaceAll(',', '')) || 0
                            : 0,
                      })}
                      value={new Intl.NumberFormat('en-US').format(
                        Number(watch('resolution.perClaimant.amount')),
                      )}
                      onChange={(e) => {
                        setValue(
                          'resolution.perClaimant.amount',
                          Number(e.target.value.replace(/,/g, '')) || 0,
                          {
                            shouldValidate: true,
                            shouldDirty: true,
                          },
                        );
                      }}
                      variant="filled"
                      fullWidth
                      type="text"
                      inputProps={{ min: 0 }}
                      error={Boolean(errors.perClaimant?.amount)}
                      helperText={errors?.perClaimant?.amount?.message}
                      disabled={!perClaimantCurrency}
                    />
                  </Grid>
                </Grid>
              </Div>
            </Grid>
          </Grid>
          <Grid container spacing={2} my={1}>
            <Grid item xs={3}>
              <CasesFormAutocomplete
                name="resolution.type"
                field={StaticPropertyKey.RESOLUTION_TYPE}
                variant="filled"
                label={t('cases.form.resolutionDetails.typeOfResolution.label')}
                error={Boolean(errors.type)}
                placeholder={t(
                  'cases.form.resolutionDetails.typeOfResolution.placeholder',
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Div sx={styles.inputWrapper}>
                <InputLabel variant="filled" sx={{ position: 'absolute' }}>
                  {t('cases.form.resolutionDetails.totalDefenseCosts.label')}
                </InputLabel>
                <Grid container columnSpacing={1} sx={{ paddingTop: '5px' }}>
                  <Grid item xs={4}>
                    <CasesFormAutocomplete
                      name="resolution.defenseCosts.currency"
                      field={StaticPropertyKey.RESOLUTION_CURRENCY}
                      variant="filled"
                      resetOnChange={() =>
                        resetField('resolution.defenseCosts.amount', {
                          defaultValue: 0,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      {...register('resolution.defenseCosts.amount', {
                        setValueAs: (value) =>
                          value
                            ? Number(value.toString().replaceAll(',', '')) || 0
                            : 0,
                      })}
                      value={new Intl.NumberFormat('en-US').format(
                        Number(watch('resolution.defenseCosts.amount')),
                      )}
                      onChange={(e) => {
                        setValue(
                          'resolution.defenseCosts.amount',
                          Number(e.target.value.replace(/,/g, '')) || 0,
                          {
                            shouldValidate: true,
                            shouldDirty: true,
                          },
                        );
                      }}
                      variant="filled"
                      fullWidth
                      type="text"
                      inputProps={{ min: 0 }}
                      error={Boolean(errors.defenseCosts?.amount)}
                      helperText={errors?.defenseCosts?.amount?.message}
                      disabled={!defenseCostsCurrency}
                    />
                  </Grid>
                </Grid>
              </Div>
            </Grid>
            <Grid item xs={3}>
              <CasesFormAutocomplete
                name="group"
                field={StaticPropertyKey.GROUP}
                variant="filled"
                label={t('cases.form.caseInformation.group.label')}
                placeholder={t('cases.form.caseInformation.group.placeholder')}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <InputLabel variant="filled" sx={{ position: 'absolute' }}>
                  {t('cases.form.resolutionDetails.isPublic.label')}
                </InputLabel>
                <Controller
                  name="resolution.isPublic"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      sx={styles.radioGroup}
                      onChange={({ target: { value } }) => {
                        field.onChange(value === 'true');
                      }}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t('confidentiality.public')}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t('confidentiality.confidential')}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} my={1}>
            <Grid item xs={12}>
              <TextField
                {...register('resolution.notes', {
                  // eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
                  setValueAs: (value: string) => (value ? value : null),
                })}
                variant="filled"
                fullWidth
                multiline
                minRows={3}
                label={t('cases.form.resolutionDetails.settlementNotes.label')}
                error={Boolean(errors.notes)}
                helperText={<>{errors.notes}</>}
              />
            </Grid>
          </Grid>
        </Div>
      )}
    </Div>
  );
};
