import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  AbuseDetailsKeys,
  ClaimNumber,
  EIncludeExcludeKeys,
  LegalDetailsKeys,
  StaticPropertyKey,
  VictimInfoAgeCategoryVariantKeys,
  VictimInfoKeys,
} from '@bvi/api-interfaces/entity/case-property';
import { AdditionalFilterParameter } from '@bvi/api-interfaces/request/case';
import { ICaseData } from '@bvi/api-interfaces/response/case';
import { ISearchFormDataSchema } from '@bvi/cases-search';

const getCaseProperty = (_case: ICaseData, field: string) =>
  get(_case, ['properties', field, 'value']);

const mapMultipleProperties = (_case: ICaseData, field: keyof ICaseData) => {
  const data = _case[field] as Array<{ id: number }>;

  return isEmpty(data) ? null : data?.map((item) => item.id).join(',');
};

const mapAgeCategories = (
  _case: ICaseData,
  ageCategoryField:
    | VictimInfoKeys.AGE_CATEGORY
    | AdditionalFilterParameter.REFINE_AGE_CATEGORY,
) => {
  const ageCategoryValue = getCaseProperty(
    _case,
    VictimInfoKeys.AGE_CATEGORY,
  ) as VictimInfoAgeCategoryVariantKeys;

  const ageCategoriesByKey = {
    [VictimInfoKeys.AGE_CATEGORY]: [
      VictimInfoAgeCategoryVariantKeys.ADULT,
      VictimInfoAgeCategoryVariantKeys.MINOR,
      VictimInfoAgeCategoryVariantKeys.PREPUBESCENT,
      VictimInfoAgeCategoryVariantKeys.TEEN,
    ],
    [AdditionalFilterParameter.REFINE_AGE_CATEGORY]: [
      VictimInfoAgeCategoryVariantKeys.MINOR_TO_ADULT,
      VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT,
      VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_TEEN,
      VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT,
    ],
  };

  return ageCategoriesByKey[ageCategoryField].includes(ageCategoryValue)
    ? ageCategoryValue
    : null;
};

export const mapCaseDataToSearch = (
  _case: ICaseData,
): Record<keyof ISearchFormDataSchema, unknown> => {
  return {
    [StaticPropertyKey.COUNTRY]: _case[StaticPropertyKey.COUNTRY]?.id,
    [StaticPropertyKey.REGION]: _case[StaticPropertyKey.REGION]?.id,
    [StaticPropertyKey.STATE]: _case[StaticPropertyKey.STATE]?.id,
    [VictimInfoKeys.AGE_CATEGORY]: mapAgeCategories(
      _case,
      VictimInfoKeys.AGE_CATEGORY,
    ),
    [AdditionalFilterParameter.REFINE_AGE_CATEGORY]: mapAgeCategories(
      _case,
      AdditionalFilterParameter.REFINE_AGE_CATEGORY,
    ),
    [VictimInfoKeys.GENDER]: getCaseProperty(_case, VictimInfoKeys.GENDER),
    [StaticPropertyKey.ORGANIZATION]: _case[StaticPropertyKey.ORGANIZATION]?.id,
    [StaticPropertyKey.DIOCESE]: _case[StaticPropertyKey.DIOCESE]?.id,
    [StaticPropertyKey.ORGANIZATION_TYPE]:
      _case[StaticPropertyKey.ORGANIZATION_TYPE]?.id,
    [VictimInfoKeys.AGE_AT_START_OF_ABUSE]: getCaseProperty(
      _case,
      VictimInfoKeys.AGE_AT_START_OF_ABUSE,
    ),
    [VictimInfoKeys.AGE_AT_END_OF_ABUSE]: getCaseProperty(
      _case,
      VictimInfoKeys.AGE_AT_END_OF_ABUSE,
    ),
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]: getCaseProperty(
      _case,
      AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW,
    ),
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]: getCaseProperty(
      _case,
      AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH,
    ),
    [AbuseDetailsKeys.LEVEL_1]:
      _case.properties[AbuseDetailsKeys.LEVEL_1].value,
    [AbuseDetailsKeys.LEVEL_2]:
      _case.properties[AbuseDetailsKeys.LEVEL_2].value,
    [AbuseDetailsKeys.LEVEL_3]:
      _case.properties[AbuseDetailsKeys.LEVEL_3].value,
    [AbuseDetailsKeys.LEVEL_4]:
      _case.properties[AbuseDetailsKeys.LEVEL_4].value,
    [AbuseDetailsKeys.LEVEL_5]:
      _case.properties[AbuseDetailsKeys.LEVEL_5].value,
    [StaticPropertyKey.JOB_TITLE]: _case[StaticPropertyKey.JOB_TITLE]?.id,
    [StaticPropertyKey.ORDER]: _case[StaticPropertyKey.ORDER]?.id,
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]: getCaseProperty(
      _case,
      AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW,
    ),
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]: getCaseProperty(
      _case,
      AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH,
    ),
    [AdditionalFilterParameter.RESOLUTION_DATE_LOW]:
      _case.resolution?.resolutionDate,
    [AdditionalFilterParameter.RESOLUTION_DATE_HIGH]: null,
    [StaticPropertyKey.LIABILITY_DEFENSES]: mapMultipleProperties(
      _case,
      StaticPropertyKey.LIABILITY_DEFENSES,
    ),
    [StaticPropertyKey.EXCEPTION]: _case[StaticPropertyKey.EXCEPTION]?.id,
    [LegalDetailsKeys.PREPARATOR]: getCaseProperty(
      _case,
      LegalDetailsKeys.PREPARATOR,
    ),
    [EIncludeExcludeKeys.CLASS_ACTION_NAME]: getCaseProperty(
      _case,
      EIncludeExcludeKeys.CLASS_ACTION_NAME,
    ),
    [EIncludeExcludeKeys.BANKRUPTCY_NAME]: getCaseProperty(
      _case,
      EIncludeExcludeKeys.BANKRUPTCY_NAME,
    ),
    [EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME]: getCaseProperty(
      _case,
      EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME,
    ),
    [StaticPropertyKey.RESOLUTION_TYPE]: _case.resolution?.type?.id,
    [LegalDetailsKeys.LAWSUIT_FILED]: getCaseProperty(
      _case,
      LegalDetailsKeys.LAWSUIT_FILED,
    ),
    [LegalDetailsKeys.HAS_ATTORNEY]: getCaseProperty(
      _case,
      LegalDetailsKeys.HAS_ATTORNEY,
    ),
    [AdditionalFilterParameter.AMOUNT_LOW]: getCaseProperty(
      _case,
      AdditionalFilterParameter.AMOUNT_LOW,
    ),
    [AdditionalFilterParameter.AMOUNT_HIGH]: getCaseProperty(
      _case,
      AdditionalFilterParameter.AMOUNT_HIGH,
    ),
    [AdditionalFilterParameter.RESOLUTION_PUBLICITY]: null,
    [ClaimNumber.NAME]: null,
  };
};
