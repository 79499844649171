/* eslint-disable prettier/prettier */
import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useController, useFormContext } from 'react-hook-form';

import { Image } from '../Image';
import { calendar } from '../icons';

import { styles } from './styles';
import { IFormDatePickerProperties } from './types';

dayjs.extend(utc);


export const FormDatePicker = (properties: IFormDatePickerProperties) => {
  const {
    field: name,
    label,
    fullWidth,
    placeholder,
    error,
    helperText,
    variant = 'outlined',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    valueFormat = 'utc',
    clearable,
    ...restProperties
  } = properties;

  const { control, resetField } = useFormContext();
  const { field } = useController({
    name,
    control,
  });

  const value = field.value ? dayjs.utc(field.value).startOf("day") : null;
  const inputId = `formDatePickerLabel_${name}`;
  const onChange = (date: Dayjs | null) => {
    if (!date) {
      field.onChange(null);
      return;
    }
    const formattedValue = date.toDate(); // Convert to Date object
    field.onChange(formattedValue);
  };
  
  const handleResetField = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    
    resetField(name);
  };

  const textFieldProperties = {
    id: inputId,
    fullWidth,
    error,
    variant,
    placeholder,
    sx: {
      ...styles.textField,
      ...(value && styles.textFieldWithClearButton),
    },
  };

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <InputLabel variant={variant} error={Boolean(error)} htmlFor={inputId}>
          {label}
        </InputLabel>
      )}
      <DatePicker
        {...field}
        {...restProperties}
        value={value}
        onChange={onChange}
        slots={{
          openPickerIcon: () => <Image src={calendar} />,
          clearButton: () => (
            <IconButton
              size="small"
              sx={styles.clearButton}
              onClick={handleResetField}
            >
              <CloseIcon sx={styles.clearIcon} />
            </IconButton>
          ),
        }}
       slotProps={{
          openPickerButton: {
            sx: styles.openPickerButton,
          },
          field: { clearable, onClear: handleResetField },
          textField: textFieldProperties,
        }}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
          </FormControl>
  );
};
